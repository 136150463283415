<template>
	<div class="content-wrap in-meet-wrap">
		<Spin fix v-if="loading"></Spin>
		<Tabs v-model="tabName" @on-click="handleClickTab">
			<TabPane label="信息" name="info">
				<Row :gutter="32">
					<Col span="12">
						<div class="text-wrap">
							<p class="text-title">会议时间</p>
							<p class="text-con">{{ formData.meetTime }}</p>
						</div>
						<div class="text-wrap">
							<p class="text-title">参会人员</p>
							<p class="text-con" style="max-height: 80px; overflow-y: auto">
								{{ formData.participants }}
							</p>
						</div>
						<div class="text-wrap">
							<p class="text-title" style="margin-bottom: 5px">上会项目</p>
							<CtmsDataGrid
								:height="dataGridHeight"
								:columns="columns"
								:show-index="false"
								:data="meetingList"
								:page="false"
							>
							</CtmsDataGrid>
						</div>
						<div
							style="text-align: center; margin-top: 20px"
							v-if="meetingList.length"
						>
							<Button
								type="primary"
								v-if="formData.voteIngStatus === 1"
								@click="handleFinishVote"
								ghost
								>终止投票</Button
							>
							<template v-if="formData.voteIngStatus === 2">
								<Button
									type="primary"
									v-if="env === 'shenzhenrenmin'"
									@click="handleExport"
									ghost
									>导出结果</Button
								>
							</template>
						</div>
					</Col>
					<Col span="12">
						<div class="text-wrap">
							<p class="text-title">扫码投票</p>
							<div class="QR-code-wrap">
								<Spin v-if="uuidLoading" fix></Spin>
								<div id="qrcodeMeet" ref="qrcodeMeet"></div>
							</div>
						</div>
						<div class="text-wrap">
							<p class="text-title">
								投票截止时间
								<Button
									size="small"
									v-if="formData.voteIngStatus !== 2"
									@click="handleEndTimeVisible"
									style="margin-left: 20px"
									>设置</Button
								>
							</p>
							<p class="text-con">{{ formData.voteDeadline || "-" }}</p>
						</div>
						<div class="text-wrap">
							<p class="text-title">投票公布方式</p>
							<RadioGroup
								v-model="formData.votePublication"
								@on-change="handleChangeType"
								type="button"
								style="margin-top: 5px"
							>
								<Radio :label="0">实名公布</Radio>
								<Radio :label="1">匿名公布</Radio>
							</RadioGroup>
						</div>
					</Col>
				</Row>
			</TabPane>
			<TabPane
				v-for="(item, index) in meetingList"
				:key="index"
				:label="`项目${index + 1}`"
				:name="`project_${item.ethicMeetingProjectId}`"
			>
				<template v-if="tabName === `project_${item.ethicMeetingProjectId}`">
					<Spin fix v-if="leftLoading"></Spin>
					<Row style="padding: 10px">
						<Col span="24">
							<span style="margin-right: 10px">{{
								item.projectName || "--"
							}}</span>
							<Tag color="success" v-if="proDetailObj.voteIngStatus === 1"
								>进行中</Tag
							>
							<Tag color="default" v-else-if="proDetailObj.voteIngStatus === 2"
								>已结束</Tag
							>
							<Tag color="error" v-else>未开始</Tag>
						</Col>
						<Col span="24">
							主要研究者：<span class="text-con">{{ item.pis || "--" }}</span>
						</Col>
					</Row>
					<Card>
						<Row style="padding: 0 10px" :gutter="32">
							<Col span="12">
								<div style="height: 100%; border-right: 1px solid #e6e6e6">
									<div
										class="section-wrap"
										v-for="(item, index) in proDetailObj.votes"
										:key="`vote_${index}`"
									>
										<h3 class="section-head">
											{{ index + 1 }}.{{ item.name }}
										</h3>
										<div
											class="text-wrap"
											v-for="(child, childIndex) in item.options"
											:key="`vote_${child.optionId}`"
										>
											<p class="text-title">
												<span style="float: left">{{ child.name }}</span>
												<span style="float: right"
													>{{ child.checkedNum }}票
													{{ child.percent || 0 }}%</span
												>
											</p>
											<p class="text-con">{{ child.checkedUsers || "-" }}</p>
										</div>
									</div>
								</div>
							</Col>
							<Col span="12">
								<div class="section-wrap">
									<h3 class="right-section-head" style="margin-bottom: 10px">
										审查结果<span class="text-con">（会议发起人填写）</span>
									</h3>
									<Form
										v-if="tabName === `project_${item.ethicMeetingProjectId}`"
										:key="`form_${item.ethicMeetingProjectId}`"
										:model="rightObj"
										label-position="top"
									>
										<template v-for="(viewItem, viewIndex) in rightObj.reviews">
											<FormItem
												:label="`${viewIndex + 1}.${viewItem.name}`"
												:key="`review_${viewIndex}`"
											>
												<RadioGroup
													v-model="viewItem.checkedId"
													@on-change="handleChangeRadio"
													vertical
												>
													<Radio
														:label="child.optionId"
														:disabled="proDetailObj.voteIngStatus !== 2"
														v-for="(child, childIndex) in viewItem.options"
														:key="`review_${childIndex}_${child.optionId}`"
														>{{ child.name }}</Radio
													>
												</RadioGroup>
											</FormItem>
										</template>
										<FormItem>
											<Button
												type="primary"
												:disabled="!isCanSubmit"
												@click="handleSubmit"
												:loading="loading"
												>提交</Button
											>
										</FormItem>
									</Form>
								</div>
							</Col>
						</Row>
					</Card>
				</template>
			</TabPane>
		</Tabs>
		<!--设置投票截止时间-->
		<Modal
			width="400"
			title="投票截止时间"
			:mask-closable="false"
			v-model="popVisible"
		>
			<TimePicker
				placeholder="投票截止时间"
				:disabled-hours="disabledHours"
				:disabled-minutes="disabledMinutes"
				format="HH:mm"
				v-model="voteDeadline"
				@on-change="handleChangeEndTime"
				style="width: 100%"
			></TimePicker>

			<div slot="footer">
				<Button style="margin-right: 8px" @click="handleCancelTime"
					>取消</Button
				>
				<Button type="primary" @click="handleSetEndTime" :loading="loading"
					>确定</Button
				>
			</div>
		</Modal>
	</div>
</template>

<script>
import api from "@/api/ethicalMeeting/index"
import QRcode from "qrcodejs2"
import { mapState } from "vuex"

const {
	getMeetDetail,
	updateVoteDeadline,
	updateVotePublication,
	getMeetingProVote,
	finishVote,
	exportCheck,
	ethicalProReview
} = api

export default {
	name: "inMeet",
	components: {},
	data() {
		return {
			loading: false,
			leftLoading: false,
			popVisible: false,
			tabName: "info",
			formData: {
				votePublication: 0 // 0 实名公布，1 匿名公布，默认0
			},
			disabledMinutes: [],
			disabledHours: [],
			qrcodeMeet: "",
			uuid: "",
			uuidLoading: false,
			meetingList: [],
			columns: [
				{
					title: "序号",
					key: "index",
					minWidth: 80,
					render: (h, params) => h("span", {}, `项目${params.index + 1}`)
				},
				{
					title: "项目名称",
					key: "projectName",
					minWidth: 150,
					tooltip: true,
					render: (h, params) => h("span", {}, params.row.projectName || "-")
				},
				{
					title: "主要研究者",
					key: "pis",
					minWidth: 120,
					tooltip: true,
					render: (h, params) => h("span", {}, params.row.pis || "-")
				}
			],
			voteDeadline: "", // 投票截止时间
			meetEndTime: "", // 会议结束时间
			projectMeetingId: "",
			proDetailObj: {},
			rightObj: {},
			time: null,
			checkedItems: [],
			checkedIds: []
		}
	},
	computed: {
		...mapState({
			dataGridHeight: state => state.contentHeight - 300
		}),
		...mapState("permission", ["systemActionPermissions"]),
		getRandomId() {
			return `prev${new Date().getTime().toString(32).toUpperCase()}`
		},
		meetId() {
			return this.$route.query.id
		},
		env() {
			return this.$store.state.environment
		},
		// 是否可以提交
		isCanSubmit() {
			if (this.proDetailObj.voteIngStatus !== 2) return false
			if (this.checkedItems && this.rightObj.reviews) {
				const optionIds = this.checkedItems.map(item => item.checkedId)
				const isEqual = this.isArrEqual(optionIds, this.checkedIds)
				if (
					this.checkedItems.length === this.rightObj.reviews.length &&
					!isEqual
				) {
					return true
				}
			}
			return false
		},
		nowHour() {
			const meetT = new Date(this.meetEndTime).getTime()
			const nowT = new Date().getTime()
			if (meetT > nowT) {
				return new Date(this.meetEndTime).getHours()
			}
			return new Date().getHours()
		},
		nowMinutes() {
			const meetT = new Date(this.meetEndTime).getTime()
			const nowT = new Date().getTime()
			if (meetT > nowT) {
				return new Date(this.meetEndTime).getMinutes()
			}
			return new Date().getMinutes()
		}
	},
	watch: {
		tabName(val) {
			if (this.time) {
				window.clearInterval(this.time)
				this.time = null
			}
			if (val !== "info" && this.formData.voteIngStatus === 1) {
				// 定时器每隔5秒刷新一次投票结果
				this.time = setInterval(() => {
					this.initProVoteDetail("left")
				}, 5000)
			}
		}
	},
	created() {
		this.init()
	},
	mounted() {
		if (this.time) {
			window.clearInterval(this.time)
			this.time = null
		}
		this.getQRcode()
	},
	methods: {
		async init() {
			this.loading = true
			const res = await getMeetDetail(this.meetId)
			if (res) {
				const sDate = res.data.startTime.split(" ")
				const eDate = res.data.endTime.split(" ")
				this.meetEndTime = res.data.endTime
				const partList = res.data.participantVOs.map(
					item => item.participantName
				)
				this.formData = {
					...res.data,
					meetTime: `${sDate[0]} ${sDate[1].slice(0, -3)} ~ ${eDate[1].slice(0, -3)}`,
					participants:
						partList.length > 1
							? partList.join("，")
							: partList.length
								? partList[0]
								: "",
					votePublication: res.data.votePublication === 1 ? 1 : 0, // 0 实名公布，1 匿名公布，默认0
					voteDeadline: res.data.voteDeadline
						? res.data.voteDeadline.split(" ")[1].slice(0, -3)
						: ""
				}
				this.meetingList = res.data.projectVOs || []
				this.tabName = "info"
			}
			this.loading = false
		},
		// 切换tab页
		handleClickTab(val) {
			this.checkedItems = []
			this.checkedIds = []
			if (val !== "info") {
				this.projectMeetingId = val.split("_")[1]
				this.initProVoteDetail()
			}
		},
		// 获取项目投票详情
		async initProVoteDetail(type) {
			if (type === "left") {
				this.leftLoading = true
			} else {
				this.loading = true
			}
			const res = await getMeetingProVote({
				ethicMeetingId: this.meetId,
				ethicMeetingProjectId: this.projectMeetingId,
				hasReview: type === "left" ? "" : 1
			})
			if (res && res.data) {
				this.proDetailObj = {
					...res.data
				}
				if (type !== "left") {
					this.rightObj.reviews = res.data.reviews.map(item => {
						let newItem = { ...item }
						if (item.options) {
							const checkedOption = item.options.find(child => child.isChecked)
							checkedOption &&
								(newItem = { ...newItem, checkedId: checkedOption.optionId })
						}
						return newItem
					})
					this.checkedItems = this.rightObj.reviews.filter(
						item => item.checkedId
					)
					this.checkedIds = this.checkedItems.map(item => item.checkedId)
				}
			}
			if (type === "left") {
				this.leftLoading = false
			} else {
				this.loading = false
			}
		},
		// 终止投票
		handleFinishVote() {
			this.$Modal.confirm({
				title: "提示",
				content: "终止投票后，参会人将无法继续投票，确认终止全部投票？",
				onOk: async () => {
					const res = await finishVote(this.meetId)
					this.init()
				}
			})
		},
		// 导出结果
		handleExport() {
			this.$asyncDo(async () => {
				const res = await exportCheck(this.meetId)
				if (res) {
					// 如果有未填写的审查决议，则提示。
					if (res.data) {
						this.$Modal.confirm({
							title: "提示",
							content: `<p style="word-break: break-all">${res.data}</p>`,
							onOk: () => {
								window.open(
									`${this.$baseUrl}/ethic-meeting/export/${this.meetId}`
								)
							}
						})
					} else {
						window.open(`${this.$baseUrl}/ethic-meeting/export/${this.meetId}`)
					}
				}
			})
		},
		// 设置投票时间弹窗出现
		handleEndTimeVisible() {
			this.disabledHours = []
			this.disabledMinutes = []
			for (let i = 0; i < this.nowHour; i++) {
				this.disabledHours.push(i)
			}
			for (let i = 0; i < 60; i++) {
				this.disabledMinutes.push(i)
			}
			this.popVisible = true
		},
		// 切换投票时间
		handleChangeEndTime(val) {
			if (!val) {
				return
			}
			const hour = val.split(":")[0]
			if (Number(hour) === this.nowHour) {
				this.disabledMinutes = []
				for (let i = 0; i < this.nowMinutes; i++) {
					this.disabledMinutes.push(i)
				}
			} else {
				this.disabledMinutes = []
			}
		},
		// 设置投票截止时间
		handleSetEndTime() {
			this.$Modal.confirm({
				title: "提示",
				content: "您确认对当前会议内所有项目设置投票截止时间吗？",
				onOk: async () => {
					this.loading = true
					const date = this.meetEndTime.split(" ")[0]
					const res = await updateVoteDeadline({
						deadlineTime: `${date} ${this.voteDeadline}:00`,
						ethicMeetingId: this.meetId
					})
					if (res) {
						this.$Message.success("操作成功")
						this.popVisible = false
					}
					this.init()
					this.loading = false
				},
				onCancel: () => {
					this.voteDeadline = ""
					this.popVisible = false
				}
			})
		},
		handleCancelTime() {
			this.voteDeadline = ""
			this.popVisible = false
		},
		// 投票公布方式
		handleChangeType(val) {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await updateVotePublication({
					votePublication: val, // 0 实名，1 匿名
					ethicMeetingId: this.meetId
				})
				if (res) {
					this.$Message.success("操作成功")
					this.init()
				}
				this.loading = false
			})
		},
		// 生成二维码
		getQRcode() {
			let url = window.location.href.split("#")[0]
			url += "#/ethical/mobile/login"
			this.uuidLoading = true
			this.uuid = `${url}?id=${this.meetId}`
			console.log("uuid", this.uuid)
			if (this.qrcodeMeet) {
				this.qrcodeMeet.makeCode(this.uuid)
			} else {
				// this.$refs.qrcodeMeet.innerHTML = ''; // 清除二维码方法
				this.qrcodeMeet = new QRcode("qrcodeMeet", {
					width: 100,
					height: 100, // 高度
					text: this.uuid // 二维码内容
				})
			}
			this.uuidLoading = false
		},
		handleChangeRadio(val) {
			this.checkedItems = this.rightObj.reviews.filter(item => item.checkedId)
		},
		isArrEqual(arr1, arr2) {
			if (!arr1 || !arr2) return false
			const itemArr = arr1.filter(t => !arr2.includes(t))
			if (arr1.length === arr2.length && !itemArr.length) {
				return true
			}
			return false
		},
		// 提交审查结果
		handleSubmit() {
			this.$asyncDo(async () => {
				// checkedIds
				this.loading = true
				const res = await ethicalProReview({
					ethicMeetingProjectId: this.projectMeetingId,
					optionIds: this.checkedItems.map(item => item.checkedId)
				})
				if (res) {
					this.$Message.success("操作成功")
					this.initProVoteDetail()
				}
				this.loading = false
			})
		}
	},
	beforeDestroy() {
		if (this.time) {
			window.clearInterval(this.time)
			this.time = null
		}
	}
}
</script>
<style lang="less">
.in-meet-wrap {
	.text-wrap {
		margin-bottom: 10px;
		.text-title {
			overflow: hidden;
			padding-right: 32px;
		}
	}
	.text-con {
		color: #999;
	}
	.right-section-head {
		font-size: 14px;
	}
	.section-head {
		padding: 5px 0;
		font-size: 14px;
		font-weight: 700;
	}
	/* 二维码*/
	.QR-code-wrap {
		position: relative;
		margin-top: 5px;
		box-sizing: border-box;
		width: 160px;
		height: 160px;
		border-style: solid;
		border-width: 2px;
		border-image-source: linear-gradient(0deg, #f9d8c1 0%, #f5c989 100%);
		border-image-slice: 1;

		#qrcodeMeet {
			display: inline-block;
		}

		img {
			width: 156px;
			height: 156px;
			border: 15px solid #fff;
		}
		&.QR-full-wrap {
			width: 400px;
			height: 400px;
			margin-top: 15vh;
			img {
				width: 396px;
				height: 396px;
			}
		}
	}
}
</style>
