import request from "@/utils/request"

export default {
	// 获取伦理会议资料列表
	getDataList: () => request("/ethic/data-file/list", { method: "get" }),
	// 上传伦理会议资料
	uploadMeetingData: data =>
		request("/ethic/data-file/upload", { method: "post", body: data }),
	// 删除伦理会议资料
	apiDelete: id =>
		request(`/ethic/data-file/delete/${id}`, { method: "delete" }),
	// 获取流程实例列表
	getProcInstList: data =>
		request("/ethic/proc-inst-list", { method: "get", params: data }),
	// 获取流程实例列表 分页
	getProcInstListPage: data =>
		request("/ethic/proc-inst-list-page", { method: "get", params: data }),
	// 获取已上会的流程实例列表
	getMeetingProcInstList: () =>
		request("/ethic/proc/selected-list", { method: "get" }),
	// 上会
	apiSetMeeting: data =>
		request("/ethic/proc/select", { method: "post", body: data }),
	// 取消上会
	apiCancelMeeting: data =>
		request("/ethic/proc/cancel-select", { method: "put", body: data }),
	// 排序已上会的流程实例列表
	apiSortMeeting: data =>
		request("/ethic/proc/selected-sort", { method: "put", body: data }),
	// 获取流程实例文件
	getFileList: data =>
		request("/ethic/proc/files", { method: "get", params: data }),

	// 获取伦理会议列表
	getMeetList: data =>
		request("/ethic-meeting/query-list", { method: "get", params: data }),
	// 查看伦理会议 详情
	getMeetDetail: id =>
		request(`/ethic-meeting/query-details/${id}`, { method: "get" }),
	// 新增伦理上会
	addEthicalMeet: data =>
		request("/ethic-meeting/create", { method: "post", body: data }),
	// 修改伦理上会
	editEthicalMeet: data =>
		request("/ethic-meeting/update", { method: "put", body: data }),
	// 删除伦理会议
	deleteEthicalMeet: id =>
		request(`/ethic-meeting/del/${id}`, { method: "delete" }),
	// 伦理上会 上传附件
	uploadFile: (data, params, signal) =>
		request("/ethic-meeting/upload-file", {
			method: "post",
			body: data,
			signal,
			params
		}),
	// 伦理会议 查询附件信息
	getMeetFiles: ethicMeetingId =>
		request(`/ethic-meeting/list-files/${ethicMeetingId}`, { method: "get" }),
	// 伦理会议 入会
	inMeeting: id => request(`/ethic-meeting/join/${id}`, { method: "get" }),
	// 获取伦理会议 项目列表
	getMeetingPro: data =>
		request("/ethic-meeting/query-project-list", {
			method: "get",
			params: data
		}),
	// 获取伦理会议 项目投票详情
	getMeetingProVote: data =>
		request(
			`/ethic-meeting/project-vote/${data.ethicMeetingId}/${data.ethicMeetingProjectId}`,
			{ method: "get", params: { hasReview: data.hasReview } }
		),
	// 修改伦理上会投票截止时间
	updateVoteDeadline: data =>
		request("/ethic-meeting/update-vote-deadline", {
			method: "put",
			body: data
		}),
	// 修改伦理上会投票公布方式
	updateVotePublication: data =>
		request("/ethic-meeting/update-vote-publication", {
			method: "put",
			body: data
		}),
	// 伦理上会投票终止
	finishVote: ethicMeetingId =>
		request(`/ethic-meeting/vote-termination/${ethicMeetingId}`, {
			method: "put"
		}),
	// 伦理会议 导出结果查验
	exportCheck: ethicMeetingId =>
		request(`/ethic-meeting/export-check/${ethicMeetingId}`, { method: "get" }),
	// 伦理上会 项目审查决议提交
	ethicalProReview: data =>
		request("/ethic-meeting/project-review", { method: "post", body: data }),
	// 伦理会议 获取参会者列表 手机端
	mobilePartList: ethicMeetingId =>
		request(`/ethic-meeting-mobile/query-participant-list/${ethicMeetingId}`, {
			method: "get"
		}),
	// 伦理上会 手机端登录
	ethicalMobileLogin: data =>
		request("/ethic-meeting-mobile/login", { method: "post", body: data }),
	// 伦理上会 手机端 与会者闲置状态检测，5s请求一次
	ethicalMobileLoginStateCheck: data =>
		request("/ethic-meeting-mobile/idle-state-check", {
			method: "post",
			body: data
		}),
	// 伦理上会 手机端 提交投票
	ethicalMobileVote: data =>
		request("/ethic-meeting-mobile/project-vote", {
			method: "post",
			body: data
		}),
	// 伦理会议 获取项目投票详情 手机端
	mobileProVoteDetail: data =>
		request(
			`/ethic-meeting-mobile/project-vote/${data.ethicMeetingId}/${data.ethicMeetingProjectId}?token=${data.token}`,
			{ method: "get" }
		)
}
